import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import imagesloaded from 'imagesloaded';
import PropTypes from 'prop-types';

let Flickity;
if (typeof window !== 'undefined') {
  Flickity = require('flickity-fade');
}

class FlickityComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flickityReady: false,
    };

    this.carousel = null;
    this.flkty = null;
  }

  componentDidMount() {
    if (!Flickity) return null;
    const {
      disableImagesLoaded,
      flickityRef,
      options,
      getOptions,
    } = this.props;

    const _options = options || getOptions();

    const carousel = this.carousel;
    this.flkty = new Flickity(carousel, _options);
    const setFlickityToReady = () => this.setState({ flickityReady: true });
    if (disableImagesLoaded) setFlickityToReady();
    else imagesloaded(carousel, setFlickityToReady);
    if (flickityRef) flickityRef(this.flkty);
  }

  componentDidUpdate(prevProps, prevState) {
    const { children, options, getOptions, reloadOnUpdate } = this.props;

    const { draggable, initialIndex } = options || getOptions();

    const { flickityReady } = this.state;
    if (reloadOnUpdate || (!prevState.flickityReady && flickityReady)) {
      this.flkty.deactivate();
      this.flkty.selectedIndex = initialIndex || 0;
      this.flkty.options.draggable =
        draggable === undefined
          ? children
            ? children.length > 1
            : false
          : draggable;
      this.flkty.activate();
    } else {
      this.flkty.reloadCells();
    }
  }

  renderPortal() {
    const { children } = this.props;
    if (!this.carousel) return null;
    const mountNode = this.carousel.querySelector('.flickity-slider');
    if (mountNode) return createPortal(children, mountNode);
  }

  render() {
    const { elementType, className } = this.props;

    return React.createElement(
      elementType,
      {
        className,
        ref: c => {
          this.carousel = c;
        },
      },
      this.renderPortal(),
    );
  }
}

FlickityComponent.propTypes = {
  disableImagesLoaded: PropTypes.bool,
  reloadOnUpdate: PropTypes.bool,
  options: PropTypes.object,
  getOptions: PropTypes.func,
  className: PropTypes.string,
  elementType: PropTypes.string,
  children: PropTypes.array,
  flickityRef: PropTypes.func,
};

FlickityComponent.defaultProps = {
  disableImagesLoaded: false,
  reloadOnUpdate: false,
  options: null,
  getOptions: null,
  className: '',
  elementType: 'div',
  children: null,
  flickityRef: () => {},
};

export default FlickityComponent;
